import dialog from '@/components/dialog'

const components = {
  install: function(Vue) {
    Vue.component('jj-dialog', dialog)

  }
}

// 导出组件
export default components
