// 金融服务
export default [
// 供应链金融买方菜单
  {
    path: '/center/finance/index',
    component: () => import('@/views/center/finance/index.vue')
  },
  {
    path: '/center/finance/apply/:id',
    component: () => import('@/views/center/finance/creditapply.vue')
  },
  {
    path: '/center/buyer/finance/orderlist',
    component: () => import('@/views/center/buyer/finance/orderlist.vue')
  },
  {
    path: '/center/buyer/finance/orderdetail/:id',
    component: () => import('@/views/center/buyer/finance/orderdetail.vue')
  },
  // 融资代采
  {
    path: '/center/buyer/finance/orderadd',
    component: () => import('@/views/center/buyer/finance/orderadd.vue')
  },
  {
    path: '/center/buyer/finance/ordertocontract/:id',
    component: () => import('@/views/center/buyer/finance/orderToContract.vue')
  },

  {
    path: '/center/buyer/finance/contractlist',
    component: () => import('@/views/center/buyer/finance/contractList.vue')
  },
  {
    path: '/center/buyer/finance/contractdetail',
    component: () => import('@/views/center/buyer/finance/contractDetail.vue')
  },
  {
    path: '/center/buyer/finance/ladingsettlelist',
    component: () => import('@/views/center/buyer/finance/ladingsettlelist.vue')
  },
  {
    path: '/center/buyer/finance/ladingsettledetail/:id',
    component: () => import('@/views/center/buyer/finance/ladingsettledetail.vue')
  },
  {
    path: '/center/buyer/finance/finacctserial/:id',
    component: () => import('@/views/center/finance/finacctseriallist')
  }

]
