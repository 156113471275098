import Vue from 'vue'
import ElementUI from 'element-ui'

import App from './App.vue'
import router from '@/router/index.js'
import MeView from 'me-view'
import SecurityView, { http, action } from 'security-view-deal'
import { parseTime, moneyFormat, linkTo } from '@/util/common'

import 'me-view/lib/index.css'
import './assets/style/index.scss'
import './assets/siconfont/iconfont.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'babel-polyfill'

import 'kindeditor/kindeditor-all-min.js'
import 'kindeditor/themes/default/default.css'

import myTip from '@/util/tip'
import NumberPrecision from 'number-precision'
import utils from './assets/script/utils'
import Storage from './assets/script/Storage'
import store from './store/index'
import Print from '@/util/print'
import * as filters from './filters' // global filters
import fmoney from './components/moneyFormat'
import echarts from 'echarts'
import eComponents from './components/index'

import FloatUtils from '@/util/floatUtils'

Vue.prototype.$echarts = echarts
Vue.use(Print)
Vue.use(MeView)
Vue.use(ElementUI)
Vue.use(SecurityView)
Vue.use(eComponents)// register global components
Vue.prototype.$myTip = myTip
Vue.prototype.$utils = utils
Vue.prototype.$linkTo = linkTo
Vue.prototype.$NP = NumberPrecision
Vue.prototype.parseTime = parseTime
Vue.prototype.moneyFormat = moneyFormat
Vue.prototype.FloatUtils = FloatUtils

Vue.config.productionTip = false
Vue.component('fmoney', fmoney)
Vue.prototype.$bus = new Vue()

// 链接到关联门户地址
const links = {}
Vue.prototype.$defaultLinkOrigin = links[location.host] || 'https://www.zkmall.com/'

Vue.prototype.bus = new Vue()

// 分类编码
const categoryCode = {
}
Vue.prototype.$categoryCode = categoryCode[location.host]

// 上传文件类型
Vue.prototype.$allowUploadFileType = {
  file: 'image/gif,image/jpeg,image/png,image/jpg,image/bmp,.doc,.docx,.xlsx,.xls,.pdf,.zip,.rar,.7z',
  image: 'image/gif,image/jpeg,image/png,image/jpg,image/bmp'
}

// 数据字典
Vue.prototype.$dictList = function (key) {
  const dictList = Storage.session.get('DATA_DICT') || []
  if (undefined === dictList || dictList === null) {
    return []
  }
  const dict = dictList.find(v => key === v.code)
  if (undefined === dict) {
    return []
  }
  if (!Reflect.has(dict, 'dictValueList') || dict.dictValueList === null) {
    return []
  }
  return dict.dictValueList.map(v => {
    return {
      label: v.name,
      value: v.code
    }
  })
}

Vue.prototype.$fileUrl = function (url) {
  if (utils.isEmpty(url)) {
    return url
  }
  if (url.toLowerCase().startsWith('http://') || url.toLowerCase().startsWith('https://')) {
    return url
  }
  // if (/^\d+\.\w+$/.test(url)) {
  return `/api/download/private?fileName=${url}`
  // }
  // return url
}

// 判断按钮权限
Vue.prototype.$hasOwn = hasOwn

/**
 * 初始化配置
 */
http.initHttp({
  timeout: 1000 * 30,
  baseURL: '/api', // 后端的上下文配置：server.servlet.context-path, 若有值则必须填写其值
  headers: { 'Content-Type': 'application/json' }
})

Vue.prototype.$http = http
/**
 * 拉取远程配置信息并初始化项目
 */
!(async () => {
  await action.loadSystemConfig()

  // 加载系统受权限保护的页面信息
  await loadProtectedPage()
  await loadDictList()
  new Vue({ router, store, render: h => h(App) }).$mount('#app')
})()

/**
 * 加载数据字典
 * @returns {Promise<void>}
 */
async function loadDictList () {
  try {
    const data = await http.get('/security/dict/item/list', {})
    Storage.session.set('DATA_DICT', data)
  } catch ({ error }) {
    Storage.session.set('DATA_DICT', [])
  }
}

/**
 * 加载收系统保护的页面地址
 */
async function loadProtectedPage () {
  const res = await http.get('/member/right/systemAllRight', {
    systemCode: 'PORTAL',
    verifiable: false
  })
  const pages = {}; const buttons = {}
  for (const o in res) {
    switch (res[o]) {
      case 'MENU_TYPE-PAGE':
        pages[o] = res[o]
        break
      case 'MENU_TYPE-BUTTON':
        buttons[o] = res[o]
        break
      default:break
    }
  }
  Storage.session.set('PROTECTED_PAGE', pages)
  Storage.session.set('PROTECTED_BUTTON', buttons)
  Storage.session.remove('LOGIN_USER_PAGE')
  Storage.session.remove('LOGIN_USER_BUTTON')
}

/**
 * 判断用户是否拥有该按钮
 * @param url 接口地址
 * @returns {boolean}
 */
function hasOwn (url) {
  const protectedButton = Storage.session.get('PROTECTED_BUTTON')
  // 判断是否存在受保护的按钮
  if (utils.isEmpty(protectedButton) || {} === protectedButton) {
    return true
  }
  // 判断指定地址是否受保护
  if (!Reflect.has(protectedButton, url)) {
    return true
  }

  const buttons = Storage.session.get('LOGIN_USER_BUTTON')
  if (buttons === undefined || buttons === null) {
    // console.error('获取登录者按钮信息失败...')
    return false
  }
  return Reflect.has(buttons, url)
}

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
