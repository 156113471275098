import {http} from 'security-view-deal';

/**
 * 资讯栏目API
 */
export default {
  treeUrl: '/cms/navigation/tree',
  tree(data) {
    return http.get(this.treeUrl, data);
  },
  listUrl: '/cms/navigation/vo/list',
  list(data) {
    return http.get(this.listUrl, data);
  },
}
