// 供应链公司
export default [
  {
    path: '/center/chain/order/list',
    component: () => import('@/views/center/chain/order/list.vue')
  },
  {
    path: '/center/chain/order/detail/:id',
    component: () => import('@/views/center/chain/order/detail.vue')
  },
  {
    path: '/center/seller/finance/contractlist',
    component: () => import('@/views/center/chain/contract/contractList.vue')
  },
  {
    path: '/center/seller/finance/contractdetail',
    component: () => import('@/views/center/chain/contract/contractDetail.vue')
  },
  {
    path: '/center/seller/finance/ladingsettlelist',
    component: () => import('@/views/center/chain/ladingsettle/ladingsettlelist.vue')
  },
  {
    path: '/center/seller/finance/ladingsettledetail/:id',
    component: () => import('@/views/center/chain/ladingsettle/ladingsettledetail.vue')
  },
  {
    path: '/center/chain/finacct/list',
    component: () => import('@/views/center/chain/finacct/list')
  },
  {
    path: '/center/chain/finacct/creditopenlist',
    component: () => import('@/views/center/chain/finacct/creditopenlist')
  },
  {
    path: '/center/chain/finacct/creditapplylist',
    component: () => import('@/views/center/chain/finacct/creditapplylist')
  },
  {
    path: '/center/chain/finacct/creditapplycheck/:id',
    component: () => import('@/views/center/chain/finacct/creditapplycheck')
  },
  {
    path: '/center/chain/finacct/creditapplydetail/:id',
    component: () => import('@/views/center/chain/finacct/creditapplydetail')
  },
  {
    path: '/center/chain/finacct/serial/:id',
    component: () => import('@/views/center/chain/finacct/finacctseriallist')
  },
  {
    path: '/center/chain/finacctrefund/finacctRefundList',
    component: () => import('@/views/center/chain/finacctrefund/finacctRefundList')
  }
]
