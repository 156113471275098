<template>
  <div>
    <el-drawer
      title=""
      :with-header="false"
      :visible.sync="showMessage"
      @close="resetForm"
      @open="openDrawer"
      direction="rtl"
      size="500px">
        <div id="drawerEl" :style="{height: windowHeight +'px',overflow: 'auto', padding: '0 15px'}">
          <div class="drawerTitle">
            <h3>消息列表</h3>
            <span v-if="$store.getters.getReadMessage != 0" @click="readAllMessage" size="small">全部设为已读</span>
            <i class="el-icon-close" @click="showMessage = false" style="cursor:pointer;float: right;margin-top: -32px;font-size: 18px;"></i>
          </div>
          <el-table :data="tableData" fit style="width: 100%;margin-top: 20px;">
          <el-table-column label="消息内容" prop="scontext" >
            <template slot-scope="scope">
              <div class="title">
                <i :style="{background: scope.row.bisread==1?'#CCCCCC':'#00BFFF'}" class="circle"></i>
                <span style="margin-left: 6px">{{ scope.row.stitle }}</span>
                <span style="color:#ED2920;float:right;cursor: pointer;" v-if="scope.row.bisread==0" @click="readOneById(scope.row)">设为已读</span>
              </div>
              <p>{{ scope.row.scontext }}</p>
            </template>
          </el-table-column>
          <el-table-column class="message-time" label="发送时间" prop="dsenddatetime" width=100>
            <template slot-scope="scope">
              <p style="color: #666;">{{ scope.row.dsenddatetime }}</p>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { ExtendHttp, http } from 'security-view-deal'
export default {

  name: 'MessageLog',
  data () {
    return {
      showMessage: false,
      windowHeight: document.body.clientHeight,
      tableData: [],
      // total: 0,
      canScroll: true,
      stop: false,
      configTitle: '',
      sendDate: '',
      formSelect: {
        stitle: null,
        pageNum: 1,
        pageSize: 20
      }
    }
  },
  created () {
    this.$bus.$on('showMessageList', (status) => {
      this.showMessage = status
      setTimeout(() => {
        document.getElementById('drawerEl').addEventListener('scroll', (e) => {
          if (this.canScroll) {
            this.handleScroll(e)
          }
        })
      }, 100)
    })
  },
  mounted () {
    this.$nextTick(() => {

    })
  },
  methods: {
    openDrawer () {
      this.stop = false
      this.formSelect.pageNum = 1
      this.findMessageReadList()
    },
    handleScroll (e) {
      var clientHeight = document.body.clientHeight
      var scrollHeight = e.srcElement.scrollHeight
      var scrollTop = e.srcElement.scrollTop
      if ((scrollHeight - parseInt((clientHeight + scrollTop))) <= 10) {
        if (!this.stop) {
          console.log('inscroll')
          this.formSelect.pageNum++
          this.findMessageReadList()
          this.stop = true
          e.srcElement.scrollTop -= 50
        }
      }
    },
    findMessageReadList () {
      if (this.sendDate.length === 2) {
        this.formSelect.beginDate = this.sendDate[0]
        this.formSelect.endDate = this.sendDate[1]
      } else {
        this.formSelect.beginDate = null
        this.formSelect.endDate = null
      }
      http.post('/message/paged', this.formSelect, ExtendHttp.HEADER_JSON).then(res => {
        // this.formSelect.pageNum = res.pageNum
        // this.formSelect.pageSize = res.pageSize
        // this.total = res.total
        if (res.list.length > 0) {
          this.tableData = this.tableData.concat(...res.list)
          this.stop = false
        } else {
          this.canScroll = false
        }
      }).catch(error => {
        this.$message.error('查询失败:', error)
      })
    },
    readAllMessage () {
      http.get('/message/readAllMessage', null).then(res => {
        this.$message.success('全部已读成功')
        this.$store.commit('setReadMessage', 0)
        this.tableData = []
        this.findMessageReadList()
      }).catch(error => {
        this.$message.error('失败:', error)
      })
    },
    readOneById (row) {
      if (row.bisread != 0) {
        return
      }
      http.post('/message/readOneById', { sguid: row.sguid }, ExtendHttp.HEADER_JSON).then(res => {
        this.$message.success('设为已读成功！')
        this.$store.commit('setReadMessage', this.$store.getters.getReadMessage - 1)
        for (const i in this.tableData) {
          if (this.tableData[i].sguid === row.sguid) {
            this.tableData[i].bisread = 1
            this.$set(this.tableData, i, this.tableData[i])
          }
        }
      }).catch(error => {
        this.$message.error('失败:', error)
      })
    },
    resetForm () {
      this.formSelect = {
        stitle: null,
        pageNum: 1,
        pageSize: 20
      }
      this.tableData = []
      this.stop = true
      this.sendDate = ''
    }

  }
}
</script>

<style lang="scss">
  :focus {
    outline: 0;
  }
  .el-drawer {
    .el-table .cell {
      .title {
        color: #999;
      }
      p{
        color: #333;
      }
    }
  }
  .drawerTitle {
    position: fixed;top: 0;z-index: 10;right: 16px;width: 484px;background:#fff;
    padding: 8px 15px 10px;
    h3 {
      padding: 5px 15px 12px;
      border-bottom: 1px solid #eee;
    }
    span {
      font-size: 12px;color: #ED2920;font-weight: normal;margin-left: 30px;cursor: pointer;
      float: left;
      margin: -29px 0 0 90px;
    }
  }
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
</style>
