// 会员中心
export default [
  // 会员
  {
    path: '/center/info',
    component: () => import('@/views/center/member/info.vue')
  },
  {
    path: '/center/userList',
    component: () => import('@/views/center/member/userList.vue')
  },
  {
    path: '/center/password',
    component: () => import('@/views/center/member/password.vue')
  },
  {
    path: '/center/mobile',
    component: () => import('@/views/center/member/mobile.vue')
  },
  {
    path: '/center/address',
    component: () => import('@/views/center/member/address.vue')
  },
  {
    path: '/center/accountInfo',
    component: () => import('@/views/center/member/accountInfo.vue')
  },
  {
    path: '/center/accountMoney',
    component: () => import('@/views/center/member/accountMoney.vue')
  },
  {
    path: '/center/accountCode',
    component: () => import('@/views/center/member/accountCode.vue')
  },
  {
    path: '/center/member/invoicetitleList',
    component: () => import('@/views/center/member/invoicetitleList.vue')
  },
  {
    path: '/center/member/collectList',
    component: () => import('@/views/center/member/collectList.vue')
  },
  {
    path: '/center/scanInfo',
    component: () => import('@/views/center/member/scanInfo.vue')
  },
  {
    path: '/center/accountDetail',
    component: () => import('@/views/center/member/accountDetail.vue')
  },
  {
    path: '/center/member/messageList',
    component: () => import('@/views/center/member/messageList.vue')
  },
  {
    path: '/center/memberUpdate',
    component: () => import('@/views/center/member/memberUpdate.vue')
  },
  {
    path: '/center/purchase',
    component: () => import('@/views/center/member/purchase.vue')
  },
  {
    path: '/center/authentication',
    component: () => import('@/views/center/member/authentication.vue')
  },
  {
    path: '/center/agreement',
    component: () => import('@/views/center/member/agreement.vue')
  },
  {
    path: '/center/contemplate',
    component: () => import('@/views/contemplate/index.vue')
  }
]
