<template>
  <div class="footer-module">
    <div class="service-box">
      <div class="w f-list-infro clearfix">
        <ul>
          <li :key="i" v-for="(item,i) in fList">
            <img :src="item.img" alt />{{item.title}}
          </li>
        </ul>
      </div>
    </div>

    <div class="service-box2 w clearfix">
      <div class="service-box2-box1">
        <ul class="clearfix">
          <li>
            <h6>友情链接</h6>
            <a href="http://www.coalchina.org.cn/" target="_blank">中国煤炭工业协会</a>
            <a href="http://www.zgcnksjxw.com/" target="_blank">苍南矿山井巷协会</a>
            <a href="http://www.sxsmtgyxh.com/" target="_blank">山西省煤炭工业协会</a>
            <a href="http://www.mjenergy.cn/" target="_blank">山西美锦能源股份有限公司</a>
            <a href="http://www.yitaigroup.com/" target="_blank">内蒙古伊泰集团有限公司</a>
            <a href="https://www.ccteg.cn/" target="_blank">中国煤炭科工集团</a>
            <a href="http://www.mackorn.cn/" target="_blank">上海美矿</a>
            <a href="http://www.shiny-control.net/" target="_blank">信亿创</a>
          </li>
          <li :key="parent.code" v-for="(parent) in helpList">
            <h6>{{parent.name}}</h6>
            <template v-for="(child, i) in (parent.children || [])">
              <a :key="child.code" @click="toDetail(child,parent.name)" href="javascript:;" v-if="i < 6">{{child.name}}</a>
            </template>
          </li>

        </ul>
      </div>
      <div class="service-box2-box3">
        <div class="logo-con">
          <img src="@/assets/img/logo.png" alt="">
        </div>
        <div class="sphone">
          <img src="@/assets/img/footer/icon-phone.png" alt="">
          010-85950040
        </div>
      </div>
      <div class="service-box2-box2">
        <div class="left">

          <img src="@/assets/img/home/kjys-code.jpeg" alt="">
          <p>中矿云商订阅号</p>
        </div>
        <div class="left">

          <img  src="@/assets/img/home/kjys-code1.jpeg" alt="">
          <p>中矿云商服务号</p>
        </div>
      </div>
      <!-- <div class="footer-left">
        <ul class="clearfix">
          <li :key="parent.code" v-for="(parent) in helpList">
            <h6>{{parent.name}}</h6>
            <template v-for="(child, i) in (parent.children || [])">
              <a :key="child.code" @click="toDetail(child)" href="javascript:;" v-if="i < 6">{{child.name}}</a>
            </template>
          </li>
        </ul>
      </div>

      <div class="footer-right">
        <div class="f">
          <div class="ser-box">
            <div>
              <p>微信公众号</p>
              <img alt src="../../../assets/img/footer-wx.png" />

            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 诚信说明 -->
    <div class="service-box3">
      <div class="wrap">
        <p class="m-b-10">
          <a :href="linkUrl" target="_blank">中矿云商</a>
          版权所有
          <a href="https://beian.miit.gov.cn/" target="_blank">京公网安备 京ICP备2022010729号-1 </a>
<!--          <a  target="_blank">ICP证: 粤B2-20210423 </a>-->
        </p>
        <div class="footer-img">
          <a href="javascript:;">
            <img height="46" src="//a1.gystatic.com/v6/images/footer/foot_img3.jpg" width="124" />
          </a>
          <a href="javascript:;">
            <img height="46" src="//a1.gystatic.com/v6/images/footer/foot_img4.jpg" width="124" />
          </a>
          <a href="javascript:;">
            <img height="46" src="//a1.gystatic.com/v6/images/footer/foot_img5.jpg" width="124" />
          </a>
          <a href="javascript:;">
            <img height="46" src="//a1.gystatic.com/v6/images/footer/foot_img7.jpg" width="124" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navigationApi from '../../../api/navigationApi'
import urlencode from "urlencode";

export default {
  inject: [ 'reload' ],
  data () {
    return {
      // linkUrl: this.$defaultLinkOrigin + '/',
      linkUrl: this.$defaultLinkOrigin,
      fList: [
        {
          title: '正品保证',
          img: require('@/assets/img/footer/t-icon11.png')
        },
        {
          title: '仓库直供',
          img: require('@/assets/img/footer/t-icon22.png')
        },
        {
          title: '规格齐全',
          img: require('@/assets/img/footer/t-icon33.png')
        },
        {
          title: '一体化服务',
          img: require('@/assets/img/footer/t-icon44.png')
        },
        {
          title: '供应链服务',
          img: require('@/assets/img/footer/t-icon5.png')
        }
      ],
      helpList: [],
      helpLists: [],
      platlink: [],
      wechaturl: ''
    }
  },
  created () {
    setTimeout(() => {
      this.platlink = this.$dictList('SYSTEM_TYPE')
      this.wechaturl = this.platlink[0].label
      console.log(JSON.stringify(this.wechaturl))
    }, 1000)
    this.queryHelpList()
  },
  methods: {
    queryHelpList () {
      navigationApi.tree({
        classify: 'SERVICE_ITEM'
      }).then(data => {
        sessionStorage.setItem('helpMenuList', JSON.stringify(data))
        // this.helpList = data;
        this.helpLists = data.length < 4 ? data : data.splice(0, 4)
        this.helpLists.forEach(item => {
          if (item.name == '关于中矿') {
            this.helpList.push(item)
          }
          if (item.name == '企业文化') {
            this.helpList.push(item)
          }
          if (item.name == '人力资源') {
            this.helpList.push(item)
          }
        })
      }).catch(error => {
        console.error('查询底部列表信息遇到异常:', error)
      })
    },
    toDetail ({ resource } = {},tabName) {
      if (resource.target && resource.target == '_BLANK') {
        var tempwindow = window.open('_blank')
        tempwindow.location = resource.url
      } else {
        this.$router.push({
          path: '/helpcenter', query: { routeId: resource.code,tabName:tabName }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.footer-module {
  background-color: #ddd;
  .service-box {
    background: #ED2920;
    min-width: 1200px;
    padding: 15px 0 15px 0;
    border-bottom: 1px solid rgba(0,0,0,0.06);
    .wrap {
      border: solid 1px #c8a977;
      font-size: 14px;
      border-radius: 6px;
      width: 1200px;
      overflow: hidden;
      color: #c8a977;
      margin: 0 auto;
      li {
        float: left;
        text-align: center;
        width: 33.33%;
        padding: 10px 0;
      }
      em {
        font-weight: bold;
      }
    }
    .f-list-infro {
      li {
        text-align: center;
        width: 20%;
        float: left;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        img {
          margin-right: 10px;
          width: 40px;
          vertical-align: middle;
        }
        h2 {
          font-size: 18px;
          color: #fff;
        }
      }
    }
  }
  .service-box2 {
    display: flex;
    position: relative;
    width: 1200px;
    margin: 10px auto 0;
    min-width: 1200px;
    margin-bottom: 40px;
    &-box1{
      width: 580px;
      height: 240px;
    }
    &-box2{
      width: 280px;
      height: 240px;
      // background: rgba(255,255,255,0.05);
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      .left {
        width: 48%;
        color: #666;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        img {
          width: 110px;
          height: 110px;
        }
        p{
          font-size: 14px;
        }
      }
    }
    &-box3{
      width: 310px;
      height: 240px;
      padding: 20px;
      .logo-con{
        padding-top: 0;
        img{
          width: 230px;
        }
      }
      .sphone{
        font-size: 28px;
        font-weight: 600;
        img{
          width: 40px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    ul {
      h6 {
        color: #000;
        font-size: 16px;
        font-weight: 700;
        display: block;
        margin-bottom: 14px;
      }
      li {
        // width: 243px;
        height: auto;
        margin-bottom: 20px;
        float: left;
        margin-left: 60px;
        margin-top: 25px;
      }
      a {
        display: block;
        color: #666;
        font-size: 13px;
        line-height: 2;
        cursor: pointer;
      }
    }
    .line {
      width: 2%;
      display: flex;
      align-items: center;
      span {
        background: #e9e9e9;
        width: 1px;
        height: 100px;
        display: inline-block;
      }
    }
    .footer-left {
      /*width: 70%;*/
      float: left;
    }
    .footer-right {
      width: 30%;
      position: absolute;
      top: 0;
      right: 0;
      .ser-box {
        width: 98%;
        position: relative;
        min-height: 190px;
        display: flex;
        justify-content: flex-end;
        img{
          width: 100px;
        }
        div:first-child {
          margin-right: 25px;
        }
        > img {
          display: inline-block;
        }
        p {
          text-align: center;
        }
      }
      .phone {
        font-weight: bold;
        display: block;
        margin-bottom: 8px;
      }
      .ask {
        width: 150px;
        padding: 8px 20px;
        border-radius: 1px;
      }
      .service-time {
        margin-bottom: 5px;
      }
    }
  }

  .service-box2 ul li.ewm {
    width: 340px;
    border: none;
    padding-left: 50px;
  }
  .service-box2 ul .ewm-l {
    width: 160px;
    float: left;
    display: inline;
    span {
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      margin-bottom: 10px;
    }
    a {
      width: 130px;
      height: 28px;
      text-align: center;
      line-height: 28px;
      border: 1px solid $color-primary-base;
      background-color: $color-primary-base;
      color: #fff;
      margin: 10px 0;
    }
  }
  .spe-bg {
    height: 35px;
    background: $color-primary-base;
  }
  .service-box3 {
    border-top: 1px solid rgba(0,0,0,0.06);
    .wrap {
      width: 1200px;
      margin: 0 auto;
      min-width: 1200px;
      padding-top: 15px;
      position: relative;
      /*border-top: 1px solid #e7e7e7;*/
      .footer-img {
        text-align: center;
        padding-bottom: 10px;
      }
      .footer-img img {
        width: 90px;
        height: auto;
      }
      p {
        font-size: 13px;
        line-height: 24px;
        color: #999;
        text-align: center;
        margin: 10px 0 30px 0;
      }
      a {
        padding: 0 10px;
        color: #999;
      }
    }
  }
}
</style>
